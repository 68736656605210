import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="show-table-button"
export default class extends Controller {
  static targets = ["table", "button"]

  connect() {
  }

  showTable(event){
    event.preventDefault();

    this.tableTarget.style.display = 'block';
    this.buttonTarget.style.display = "none"
  }
}
