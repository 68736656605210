import { Controller } from "@hotwired/stimulus"
import consumer from "../channels/consumer"

export default class extends Controller {
    static values = { jobId: String, redirectUrl: String, errorUrl: String, url: String }

    connect() {
        const url = this.redirectUrlValue
        const errorRedirectUrl = this.errorUrlValue
        this.subscription = consumer.subscriptions.create(
            { channel: "ProcessTransactionsChannel", job_id: this.jobIdValue },
            {
                received(data) {
                    if (data.status === 'completed') {
                        window.location.href = url;
                    }

                    if (data.status === 'error') {
                        window.location.href = errorRedirectUrl;
                    }
                }
            }
        );

        fetch(this.urlValue).then(() => {
            //I'm listening for job to complte so just eat this
        });
    }


    disconnect() {
        this.subscription.unsubscribe();
    }
}