let autoSubmitTimeout = null;
let loaderTimeout = null;
$(document).on('turbolinks:load formReplacedEvent', () => {
  var elements = document.querySelectorAll('[data-change~="auto_submit"]'), i;
  for (i = 0; i < elements.length; ++i) {
    element = elements[i];
    console.log(element.dataset.delay);

    $(element).on('input', (e) => {
      // Add delay before submitting
      var delay
      if (e.target.dataset.delay == null) {
        delay = 500
      } else {
        delay = e.target.dataset.delay
        console.log(delay)
      }

      if (autoSubmitTimeout){
        clearTimeout(autoSubmitTimeout)
      }
      if (loaderTimeout){
        clearTimeout(loaderTimeout)
        loaderTimeout = setTimeout(() =>  {
          loadingSpinner.style.display = "none"
        }, delay + 100);
      }

      var loadingSpinner = document.getElementById($(e.target).closest('form')[0].id.replace('form-','loader-'));
      if (loadingSpinner != null) {
        loadingSpinner.style.display = "inline-block";
      }

      var loadingMessages = document.querySelectorAll('[id^="loading-message-"]');
      for (var i = loadingMessages.length; i--;) loadingMessages[i].style.display = "inline-block";

      autoSubmitTimeout = setTimeout(() =>  {
        Rails.fire($(e.target).closest('form')[0], 'submit');
      }, delay);

    });
  }
});