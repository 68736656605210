import consumer from "./consumer"

consumer.subscriptions.create(
    {channel: "ProcessStateInfoChannel"},
    {
        initialized() {},

        rejected: () => {
        },

        connected: () => {
            console.log("3333")
        },

        disconnected: () => {
        },

        received: (data) => {
            var nexusStateId = $('#stateInfoMainContent').data('field-id');
            if (nexusStateId === data.nexus_state_id) {
                if (data.status === 'success') {
                    $('#stateInfoMainContent').html(data.partial);
                    $('[data-toggle="tooltip"]').tooltip();
                    $(document).trigger('formReplacedEvent', []);
                } else if (data.status === 'failure') {
                    $('#stateInfoMainContent').html(data.partial)
                }
            }
        },
    }
);