$(document).on('turbolinks:load', function () {
    let backToTopButton = document.getElementById("btn-back-to-top");
    let scrollableElements = document.getElementsByClassName('body-background')

    if (backToTopButton != null) {
        if (scrollableElements.length > 0) {
            scrollableElements[0].addEventListener('scroll', (event) => {
                var formTop = document.getElementById('form-top')
                if (formTop != null) {
                    if (!isElementVisible(formTop, null)) {
                        backToTopButton.style.display = "block";
                    } else {
                        backToTopButton.style.display = "none";
                    }
                }
            });
        }

        backToTopButton.addEventListener("click", (event) => {
            document.getElementById('form-top').scrollIntoView({behavior: "smooth", block: 'center'});
        });
    }
});

function isElementVisible(el, holder) {
    holder = holder || document.body
    const {top, bottom, height} = el.getBoundingClientRect()
    const holderRect = holder.getBoundingClientRect()

    return top <= holderRect.top
        ? holderRect.top - top <= height
        : bottom - holderRect.bottom <= height
}
