import consumer from "./consumer"

consumer.subscriptions.create(
    {channel: "ProcessNexusHomeChannel"},
    {
        initialized() { },

        rejected: () => {
        },

        connected: () => { },

        disconnected: () => {
        },

        received: (data) => {
            var nexusStudyId = $('#showMainTable').data('field-id');
            if (nexusStudyId === data.nexus_study_id) {
                if (data.status === 'success') {
                    $('#showMainTable').html(data.partial);
                    $('[data-toggle="tooltip"]').tooltip();
                    $(document).trigger('formReplacedEvent', []);
                } else if (data.status === 'failure') {
                    $('#showMainTable').html(data.partial)
                }
            }
        },
    }
);