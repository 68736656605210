import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["checkbox", "all"]

    connect() {
        const allChecked = this.checkboxTargets.every(checkbox => checkbox.checked);
        if (allChecked) {
            this.allTarget.checked = true;
        }
    }

    toggleAll() {
        const isChecked = this.allTarget.checked;
        this.checkboxTargets.forEach((checkbox) => {
            checkbox.checked = isChecked;
        });
    }

    toggleSingle() {
        const allChecked = this.checkboxTargets.every(checkbox => checkbox.checked);
        if (!allChecked) {
        }
    }
}