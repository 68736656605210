import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static values = { url: String, redirectUrl: String }

    connect() {
        this.load()
    }

    load() {

        if (this.redirectUrlValue != null && this.redirectUrlValue.length > 0) {
            fetch(this.urlValue)
                .then(() => {
                    window.location.href = this.redirectUrlValue;
                });
        }else {
            fetch(this.urlValue)
                .then(response => response.text())
                .then(html => this.element.innerHTML = html)
                .then(() => {
                    //TODO this is a hack for a specific circumstance. Need to make this work for generic or move this to a seperate
                    //controller
                    $('[data-toggle="tooltip"]').tooltip();
                    $(document).trigger('formReplacedEvent', []);
                });
        }

    }
}