import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static values = {url: String, redirectUrl: String}

    connect() {
        fetch(this.urlValue).then(() => {});
    }

    disconnect() { }
}