
var applySelect2 = function () {
    $('[data-uses-select2]').each(function () {
        var $node = $(this);
        if (!$node.data('uses-select2-initialized')) {
            var placeholder = $node.attr('placeholder') || null;
            $node.select2({
                width: 'auto',
                theme: 'bootstrap',
                placeholder: placeholder,
                width: '100%'
            });
            $node.data('uses-select2-initialized', true);
        }
    });
};

$(document).on('turbolinks:load', function () {
//   applySelect2();
});