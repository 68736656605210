import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["toggleable"];

    connect() {
        this.toggle();
    }

    toggle() {
        const isVisible = this.element.querySelector('input[type="radio"]:checked').value === "show";
        console.log("Is Visible: " + isVisible)
        console.log(this.element.querySelector('input[type="radio"]:checked'))
        this.toggleableTargets.forEach((el) => {
            el.style.display = isVisible ? "block" : "none";
        });
    }
}