import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["previewItem", "previewItemPreview", "previewItemLoader"]

    connect() {
        $(this.previewItemTarget).on('select2:select select2:unselect', function () {
            let event = new Event('change', {bubbles: true});
            this.dispatchEvent(event);
        });
    }

    previewItemUpdated() {
        this.previewItemPreviewTarget.style.display = 'none';
        this.previewItemLoaderTarget.style.display = "block"
        var url = this.data.get("previewUrl");
        var columnHeader = $(this.previewItemTarget).find(':selected').text();
        url += `?column_header=${columnHeader}`

        Rails.ajax({
            type: 'GET',
            url: url,
            dataType: 'json',
            success: (data) => {
                this.previewItemLoaderTarget.style.display = "none"
                this.previewItemPreviewTarget.style.display = 'block';
                this.previewItemPreviewTarget.textContent = data.valueString;
            }
        })
    }

    previewMultiItemUpdated() {

        var columnHeaders = $(this.previewItemTarget).select2("val")
        if (columnHeaders.length === 0){
            this.previewItemLoaderTarget.style.display = "none"
            this.previewItemPreviewTarget.style.display = 'block';
            this.previewItemPreviewTarget.textContent = ""
            return
        }


        this.previewItemPreviewTarget.style.display = 'none';
        this.previewItemLoaderTarget.style.display = "block"
        var url = this.data.get("previewUrl");
        const joinedHeaders = columnHeaders.join('|=?aoiw9jwek12w11-12123?=|');
        url += `?column_header=${joinedHeaders}`

        Rails.ajax({
            type: 'GET',
            url: url,
            dataType: 'json',
            success: (data) => {
                this.previewItemLoaderTarget.style.display = "none"
                this.previewItemPreviewTarget.style.display = 'block';
                this.previewItemPreviewTarget.classList.add('preformatted');
                this.previewItemPreviewTarget.textContent = data.valueString.replaceAll('|=?aoiw9jwek12w11-12123?=|',"\n");
            }
        })
    }
}