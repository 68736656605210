import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["entries", "pagination"]

    initialize() {
        let options = {
            rootMargin: '200px',
        }

        this.intersectionObserver = new IntersectionObserver(entries => this.processIntersectionEntries(entries), options)
    }

    paginationTargetConnected(element) {
        this.loadMore()
    }

    connect() {
        this.intersectionObserver.observe(this.paginationTarget)
    }

    disconnect() {
        this.intersectionObserver.unobserve(this.paginationTarget)
    }

    processIntersectionEntries(entries) {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                this.loadMore()
            }
        })
    }

    loadMore() {
        let next_page = this.paginationTarget.querySelector("a[rel='next']")

        if (next_page == null) { return }
        let url = next_page.href

        Rails.ajax({
            type: 'GET',
            url: url,
            dataType: 'json',
            success: (data) => {
                var replaceDiv
                var spinner
                if (data.isOverview) {
                    replaceDiv = document.getElementById('overview-table');
                    spinner = document.getElementById('overview-spinner');
                } else {
                    replaceDiv = document.getElementById('exposure-table');
                    spinner = document.getElementById('exposure-spinner');
                }
                replaceDiv.insertAdjacentHTML('beforeend', data.entries)
                this.paginationTarget.innerHTML = data.pagination
                spinner.style.display = 'none';
                $('[data-toggle="tooltip"]').tooltip();
            }
        })
    }
}