// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import "bootstrap"
import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "@fortawesome/fontawesome-free/css/all"
import 'select2'
import {PDFDocument} from 'pdf-lib'
import toastr from "toastr";
import 'stylesheets/application'
import flatpickr from "flatpickr";
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect';
import 'bootstrap-icons/font/bootstrap-icons.css'
import "/app/javascript/controllers"

global.toastr = toastr

Rails.start()
Turbolinks.start()
ActiveStorage.start()

require("jquery-ui/ui/widget")
require("jquery-ui/ui/widgets/sortable")
require("trix")
require("@rails/actiontext")
require("src/apply-select2")
require("src/remote-auto-submit")
require("src/smooth-scroll")
require("src/direct-uploads")
require("src/back-to-top")
require("@nathanvda/cocoon")

window.PDFDocument = PDFDocument;
$(document).on("turbolinks:load", () => {

    window.addEventListener("trix-file-accept", function (event) {
    })

    const tx = document.getElementsByClassName("autogrow-textarea");
    for (let i = 0; i < tx.length; i++) {
        tx[i].setAttribute("style", "height:" + (tx[i].scrollHeight) + "px;overflow-y:hidden;");
        tx[i].addEventListener("input", OnInput, false);
    }

    function OnInput() {
        this.style.height = "auto";
        this.style.height = (this.scrollHeight) + "px";
    }

    toastr.options = {
        positionClass: 'toast-top-center'
    };

    $('[data-toggle="tooltip"]').tooltip();

    $(".nexus_actions").select2({
        multiple: true,
        width: "100%"
    });

    $(".partner-managers").select2({
        multiple: true,
        width: "100%"
    });


    $("#sortable").sortable({
        handle: '.handle',
        update: function (e, ui) {
            Rails.ajax({
                url: $(this).data("url"),
                type: "PATCH",
                data: $(this).sortable('serialize', {expression: (/(.+)[_](.+)/)}),
            });
        }
    });

    $("#sortable1").sortable({
        handle: '.handle',
        update: function (e, ui) {
            Rails.ajax({
                url: $(this).data("url"),
                type: "PATCH",
                data: $(this).sortable('serialize', {expression: (/(.+)[_](.+)/)}),
            });
        }
    });

    $(document).on("turbolinks:before-cache", function () {
        $('select').select2('destroy');
    });

    flatpickr(".flatpickr-month-year", {
        plugins: [
            new monthSelectPlugin({
                shorthand: true,
                dateFormat: "m/Y",
                altInput: true,
                altFormat: "m/Y",
            }),
        ],
        altInput: true,
        altFormat: "m/Y",
        dateFormat: "Y-m-d",
        disableMobile: true,
    });

    flatpickr('.flatpickr-date', {
        altInput: true,
        altFormat: "F j, Y",
        maxDate: "today",
        dateFormat: "Y-m-d"
    });

    // flatpickr('.flatpickr-month-year', {
    //   altInput: true,
    //   altFormat: "F, Y",
    //   dateFormat: "Y-m-d",
    // });


    flatpickr('.flatpickr-date-disabled', {
        altInput: true,
        altFormat: "F j, Y",
        dateFormat: "Y-m-d",
        clickOpens: false
    });

    $('form').on('cocoon:after-insert', function () {
        $('select').select2({
            width: "100%",
        });

        flatpickr('.flatpickr-date', {
            altInput: true,
            altFormat: "F j, Y",
            dateFormat: "Y-m-d",
            maxDate: "today",

        });

    });

    $(".modalSelect2").select2({
        width: "100%"
    });

    $(".basicSelect2").select2({
        width: "100%"
    });

    $(".basicSelect2Clear").select2({
        width: "100%",
        allowClear: true
    });


    $(".twoFactorSelect2").select2({
        width: "100%",
        tags: true
    });

    $(".answerSelect").select2({
        width: "100%",
        selectOnClose: true
    });

    $(document).on('keydown', '.answerSelect', function (e) {
        if (e.originalEvent && e.which === 40) {
            e.preventDefault();
            $(this).siblings('select').select2('open');
        }
    });

    $('[data-input-disabled="disabled"]').each(function () {
        var $node = $(this);
        $node.attr('disabled', 'disabled');
    });

    $('#clientDisclaimerModal').modal('show');

    //***
    //Portfolio Search
    //***
    var indexPortfolioInput = $("#portfolio-search-input");
    var partnerFirmPortfolioInput = $("#portfolio-partner-search-input");
    var searchInput = null;
    var showSearchQuery = null;
    if (indexPortfolioInput.length > 0) {
        searchInput = indexPortfolioInput
        showSearchQuery = function () {
            $.get('/portfolios/search', {search: searchInput.val()}, function (data) {
            });
        };
    } else if (partnerFirmPortfolioInput.length > 0) {
        searchInput = partnerFirmPortfolioInput
        showSearchQuery = function () {
            $.get('/portfolios/search_partners', {search: searchInput.val()}, function (data) {
            });
        };
    }

    if (searchInput != null) {
        var debounceTimeout = null;
        searchInput.on('input', function (event) {
            clearTimeout(debounceTimeout);
            debounceTimeout = setTimeout(showSearchQuery, 500);
        });
    }

    $(".scroll").click(function (event) {
        event.preventDefault();
        document.getElementById(this.hash.substring(1)).scrollIntoView({behavior: "smooth", block: 'center'});
    });

    let cardElement = document.querySelector("#card-element")

    if (cardElement !== null) {
        setupStripe()
    }

    // Handle users with existing card who would like to use a new one
    let newCard = document.querySelector("#use-new-card")
    if (newCard !== null) {
        newCard.addEventListener("click", (event) => {
            event.preventDefault()
            document.querySelector("#payment-form").classList.remove("d-none")
            document.querySelector("#existing-card").classList.add("d-none")
        })
    }
});

function setupStripe() {
    const stripe_key = document.querySelector("meta[name='stripe-key']").getAttribute("content")
    const stripe = Stripe(stripe_key)

    const elements = stripe.elements()
    const card = elements.create('card')
    card.mount('#card-element')

    var displayError = document.getElementById('card-errors')

    card.addEventListener('change', (event) => {
        if (event.error) {
            displayError.textContent = event.error.message
        } else {
            displayError.textContent = ''
        }
    })

    const form = document.querySelector("#payment-form")
    let paymentIntentId = form.dataset.paymentIntent
    let setupIntentId = form.dataset.setupIntent
    if (paymentIntentId) {
        if (form.dataset.status == "requires_action") {
            stripe.confirmCardPayment(paymentIntentId, {setup_future_usage: 'off_session'}).then((result) => {
                if (result.error) {
                    displayError.textContent = result.error.message
                    form.querySelector("#card-details").classList.remove("d-none")
                } else {
                    form.submit()
                }
            })
        }
    }

    form.addEventListener('submit', (event) => {
        event.preventDefault()

        let name = form.querySelector("#name_on_card").value
        let data = {
            payment_method_data: {
                card: card,
                billing_details: {
                    name: name,
                }
            }
        }

        // Complete a payment intent
        if (paymentIntentId) {
            stripe.confirmCardPayment(paymentIntentId, {
                payment_method: data.payment_method_data,
                setup_future_usage: 'off_session',
                save_payment_method: true,
            }).then((result) => {
                if (result.error) {
                    toastr.options.timeOut = 3000;
                    toastr.options.positionClass = "toast-top-right";
                    toastr.error(result.error.message);
                    form.querySelector("#card-details").classList.remove("d-none")
                    document.getElementById('nexus-submit-button').removeAttribute('data-disable-with')
                } else {
                    form.submit()
                }
            })

            // Updating a card or subscribing with a trial (using a SetupIntent)
        } else if (setupIntentId) {
            stripe.confirmCardSetup(setupIntentId, {
                payment_method: data.payment_method_data
            }).then((result) => {
                if (result.error) {
                    toastr.options.timeOut = 3000;
                    toastr.options.positionClass = "toast-top-right";
                    toastr.error(result.error.message);
                    document.getElementById('nexus-submit-button').removeAttribute('data-disable-with')
                } else {
                    addHiddenField(form, "card_token", result.setupIntent.payment_method)
                    form.submit()
                }
            })

        } else {
            // Subscribing with no trial
            data.payment_method_data.type = 'card'
            stripe.createPaymentMethod(data.payment_method_data).then((result) => {
                if (result.error) {
                    toastr.options.timeOut = 3000;
                    toastr.options.positionClass = "toast-top-right";
                    toastr.error(result.error.message);
                    document.getElementById('nexus-submit-button').removeAttribute('data-disable-with')
                } else {
                    addHiddenField(form, "card_token", result.paymentMethod.id)
                    form.submit()
                }
            })
        }
    })
}

function addHiddenField(form, name, value) {
    let input = document.createElement("input")
    input.setAttribute("type", "hidden")
    input.setAttribute("name", name)
    input.setAttribute("value", value)
    form.appendChild(input)
}