import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["input", "link", "columnHeader", "stateValue"]

    connect() {
        $(this.columnHeaderTarget).on('select2:select', function () {
            let event = new Event('change', {bubbles: true});
            this.dispatchEvent(event);
        });

        if (this.hasStateValueTarget) {
            $(this.stateValueTarget).on('select2:select', function () {
                let event = new Event('change', {bubbles: true});
                this.dispatchEvent(event);
            });

            var allState = this.stateValueTarget.dataset["allState"];
            if (allState != null && allState.trim() !== '') {
                var filterType = this.linkTarget.dataset["filterType"]
                $("#state-input-" + filterType).val(allState).trigger('change');
            }
        }

    }

    stateUpdated() {
        var state = $(this.stateValueTarget).find(':selected').text();
        var filterType = this.linkTarget.dataset["filterType"];
        document.getElementById("state-output-" + filterType).textContent = state;
    }

    headerUpdated() {
        var columnHeader = this.columnHeaderTarget.value
        var filterType = this.linkTarget.dataset["filterType"]
        document.getElementById("header-output-" + filterType).textContent = columnHeader;
    }

    handleInputChange() {
        var columnValue = this.linkTarget.value
        var filterType = this.linkTarget.dataset["filterType"]

        if (columnValue == null || columnValue.trim() === '') {
            document.getElementById("value-output-" + filterType).textContent = "|Cell Value|";
        } else {
            document.getElementById("value-output-" + filterType).textContent = columnValue;
        }
    }

    handleStateClick() {
        var selectedStates = this.stateValueTarget.value
        var url = this.stateValueTarget.dataset["url"]
        var filterType = this.stateValueTarget.dataset["filterType"]
        url += `?filter_type=${filterType}&selected_states=${selectedStates}`
        fetch(url, {
            headers: {
                'Accept': 'application/javascript',
            }
        })
            .then(response => response.text()) // Get the response text
            .then(js => eval(js)) // Evaluate the JavaScript code in the response
            .catch(error => console.error('Error:', error));
    }

}