import consumer from "./consumer";


consumer.subscriptions.create(
    { channel: "ProcessTransactionsChannel" },
    {
        connected: () => {
            console.log("222")
        },

        received(data) {
            // mainUploadContent
            let mainContentUpload = $("#main-upload-content-" + data.nexus_activity_upload_id);

            if (mainContentUpload.length) {
                if (data.status === 'success') {
                    $("#main-upload-content-" + data.nexus_activity_upload_id).html(data.partial);
                    $('[data-toggle="tooltip"]').tooltip();
                    $(document).trigger('formReplacedEvent', []);
                } else if (data.status === 'failure') {
                    $("#main-upload-content-" + data.nexus_activity_upload_id).html(data.partial)
                }
            }

            let iconLayout = $("#icon-layout-" + data.nexus_activity_upload_id);
            if (iconLayout.length) {
                iconLayout.html(data.icon_layout_partial);
            }

            let transaction_count_layout = $("#transaction-count-layout-" + data.nexus_activity_upload_id);
            if (transaction_count_layout.length) {
                transaction_count_layout.html(data.transaction_count_partial);
            }
        }
    }
);