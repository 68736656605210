import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="show-table-button"
export default class extends Controller {
    static targets = ["button"]

    connect() {}

    showTable(event){
        event.preventDefault();

        var divsToHide = document.getElementsByClassName("hideable-table-row"); //divsToHide is an array
        for(var i = 0; i < divsToHide.length; i++){
            divsToHide[i].style.display = "table-row";
        }

        this.buttonTarget.style.display = "none"
    }
}
