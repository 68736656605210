import {Controller} from "@hotwired/stimulus"
import * as events from "events";

export default class extends Controller {
    static targets = ["input", "select", "form"];
    static values = { debounce: Number, events: String, showContinuousLoading: Boolean };

    connect() {
        this.events = this.eventsValue.split(" ");
        this.debounce = this.hasDebounceValue ? this.debounceValue : 500;

        this.selectTargets.forEach(select => {
            $(select).on('select2:select select2:unselect', this.autoSave.bind(this));
        });


        this.inputTargets.forEach(input => {
            if (input.matches('trix-editor')) {
                input.addEventListener('trix-change', this.autoSave.bind(this));
            } else {
                this.events.forEach(event => {
                    input.addEventListener(event, this.autoSave.bind(this));
                });
            }
        });
    }

    autoSave(event) {
        clearTimeout(this.timeout);
        var loadingSpinner = document.getElementById("loading-spinner");
        var loadingMessage = document.getElementById("loading-message");
        if (loadingSpinner != null) {
            loadingSpinner.style.display = "inline-block";
            loadingMessage.style.display = "inline-block";
        }

        if (this.showContinuousLoadingValue) {
            this.formTarget.requestSubmit();
        } else {
            this.timeout = setTimeout(() => {
                this.formTarget.requestSubmit();
                loadingSpinner.style.display = "none"
                loadingMessage.style.display = "none"
            }, this.debounce);
        }
    }

    disconnect() {
        this.inputTargets.forEach(input => {
            this.events.forEach(event => {
                input.removeEventListener(event, this.autoSave.bind(this));
            });
        });
    }
}