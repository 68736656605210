import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["content", "toggleButton"]
    static values = {
        moreText: String,
        lessText: String,
    }

    connect() {
        this.open = false
        this.updateLineCount();
    }

    toggle(event) {
        this.open === false ? this.show(event) : this.hide(event)
    }

    show(event) {
        this.open = true

        const target = event.target
        target.innerHTML = this.lessTextValue
        this.contentTarget.style.setProperty("--read-more-line-clamp", "'unset'")
        this.updateLineCount();
    }

    hide(event) {
        this.open = false

        const target = event.target
        target.innerHTML = this.moreTextValue
        this.contentTarget.style.removeProperty("--read-more-line-clamp")
        this.updateLineCount();
    }

    updateLineCount() {
        const contentElement = this.contentTarget;
        const lineHeight = parseInt(window.getComputedStyle(contentElement).lineHeight);
        const scrollHeight = contentElement.scrollHeight;
        const numberOfLines = scrollHeight / lineHeight;

        // Hide the toggle button if the content is four lines or less
        if (Math.ceil(numberOfLines) <= 5) {
            this.toggleButtonTarget.style.display = 'none';
        } else {
            this.toggleButtonTarget.style.display = ''; // Show button if there are more than 4 lines
        }
    }
}